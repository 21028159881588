<template>
  <div class="box">
    <div class="title">{{ detail.title }}</div>
    <div class="createTime">{{ detail.createTime }}</div>
    <div v-html="detail.content"></div>
  </div>
</template>
<script>
import { Cell } from "vant";
import { getOperatingGuideById } from "../api/guide";
import { wxPublicAuth } from "../api/home";
export default {
  name: "guideDetail",
  components: {
    "van-cell": Cell,
  },
  data() {
    return {
      detail: {
        title: "",
        content: "",
      },
    };
  },
  created() {
    console.log(this.$route.query.pid);
    getOperatingGuideById({
      id: this.$route.query.pid,
    }).then((res) => {
      console.log(res);
      if (res.code == 200) {
        this.detail = res.data;
        this.detail.content = this.detail.content.replace(
          /<img/g,
          "<img style='max-width:100%;height:auto;'"
        );
      } else {
      }
    });

    
  },
  methods: {
    wxPublicAuth() {
      wxPublicAuth({
        page: "home",
      })
        .then((res) => {
          console.log(res);

          window.location.href = res.data;
        })
        .catch((res) => {
          console.log(res);
        });
    },
  },
};
</script>
<style>
.box {
  padding: 1rem;
}
.title {
  font-size: 1rem;
  text-align: left;
}
.createTime {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.3);
  padding: 0.2rem 0;
}
.van-cell__title {
  display: flex;
}
</style>


